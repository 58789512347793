<template>
  <div class="page-content">
    <div class="card">
      <div class="card-body" v-loading="loading.table">
        <div class="d-flex justify-content-between align-items-center pb-3">
          <div class="d-flex">
            <a class="cursor-pointer text-bold" @click="back"><i class="el-icon-back"></i></a>
            <h5 class="ml-2">{{ flow_detail.name }} Responses</h5>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-2">
            <el-select v-model="search.type" size="small" class="w-100">
              <el-option value="chatbot" label="Chatbot" />
              <el-option value="batch" label="Broadcast" />
            </el-select>
          </div> -->
          <div class="col-md-2">
            <el-select v-model="search.date_by" size="small" width="100%">
              <el-option value="submitted_at" label="Submitted Time" />
              <el-option value="send_at" label="Send Flow Time" />
            </el-select>
          </div>
          <div class="col-md-4">
            <el-date-picker
              v-model="search.date_range"
              type="daterange"
              size="small"
              :picker-options="date_picker_options"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          </div>
          <div class="col-md-3">
            <el-input size="small" v-model="search.search" placeholder="Search here..."></el-input>
          </div>
          <div class="col-md-2 d-flex">
            <el-button :loading="loading.table" @click="search.page = 1; getList();" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
            <el-button @click="search.page = 1; downloadList();" size="small" class="btn-default" icon="el-icon-download">Download</el-button>
          </div>
        </div>
        <hr>
        <div class="row mt-4">
          <div class="col-12 col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover table-ordered">
                <thead>
                  <tr>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Type</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Template Name</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Customer Name</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Customer Phone</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Sent at</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Submitted at</span>
                      </div>
                    </th>
                    <th v-for="(v, idx) in flow_field" :key="idx">
                      <div class="d-flex align-items-center">
                        <span>{{ v.label }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list" :key="item.id">
                    <td>{{ item.type.toUpperCase() }}</td>
                    <td>{{ item.template_name }}</td>
                    <td>{{ item.message.contact.display_name || item.message.contact.name }}</td>
                    <td>{{ item.message.contact.phone_number }}</td>
                    <td>{{ item.send_at_str }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td v-for="(v, idx) in flow_field" :key="idx" v-html="item[v.name]"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        <b-pagination v-if="totalRow > per_page" v-model="currentPage" :total-rows="totalRow" :per-page="per_page"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import popupErrorMessages from '@/library/popup-error-messages';
import flowsAPI from '../../../api/flows';
import reportApi from '../../../api/reports';

export default {
  name: 'FlowsResponse',
  metaInfo: {
    title: 'Response Flows',
  },
  data() {
    return {
      loading: {
        table: false,
        download: false,
      },
      list: [],
      search: {
        page: this.$route.query.page || 1,
        search: this.$route.query.search || '',
        type: this.$route.query.type || '',
        date_by: this.$route.query.date_by || 'submitted_at',
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
      },
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      per_page: 50,
      totalRow: 0,
      flow_id: this.$route.params.id,
      flow_detail: {},
      flow_field: [],
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        console.log(val);
        this.search.page = val;
        this.getList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    back() {
      this.$router.back();
    },
    doSearch() {
      this.page = 1;
      this.getList();
    },
    getList() {
      const options = {
        page: this.search.page,
        type: this.search.type,
        search: this.search.search,
        date_by: this.search.date_by,
      };
      if (this.search.date_range) {
        options.start_date = moment(this.search.date_range[0]).format('YYYY-MM-DD');
        options.end_date = moment(this.search.date_range[1]).format('YYYY-MM-DD');
      }
      this.loading.table = true;
      this.totalRow = 0;
      this.list = [];
      flowsAPI.response({
        workspace_id: this.activeWorkspace._id,
        flow_id: this.flow_id,
        options,
      }).then(async (res) => {
        this.loading.table = false;
        await popupErrorMessages(res);
        // set field
        this.flow_detail = res.data.flow;
        this.flow_detail.json_data.screens.forEach((v) => {
          v.layout.children.forEach((x) => {
            if (x.name) {
              this.flow_field.push({
                name: x.name,
                label: x.label,
              });
            }
          });
        });
        // list data
        this.totalRow = res.data.response.count;
        this.list = res.data.response.rows.map((v) => {
          // v.categories_str = v.categories.join(', ');\
          const keys = Object.keys(v.response_json);
          delete keys.flow_source;
          delete keys.flow_type;
          delete keys.flow_token;
          const result_keys = keys.filter((y) => !y.includes('value_', 0) && !y.includes('list_', 0));
          result_keys.forEach((z) => {
            if (v.response_json[z]) {
              if (Array.isArray(v.response_json[z])) {
                v[z] = v.response_json[z].join(', ');
              } else if (z.includes('OptIn_', 0)) {
                if (v.response_json[z] === true) {
                  v[z] = '<i class="el-icon-check"></i>';
                } else if (v.response_json[z] === false) {
                  v[z] = '<i class="el-icon-close"></i>';
                } else {
                  v[z] = v.response_json[z];
                }
              } else {
                v[z] = v.response_json[z];
              }
            } else {
              v[z] = '-';
            }
          });
          v.template_name = '';
          if (v.type === 'chatbot') {
            v.template_name = v.chatbot_keyword.title;
          } else if (v.type === 'batch') {
            v.template_name = v.broadcast_batch.batch_name;
          }
          ['created_at', 'send_at'].forEach((date) => {
            v[`${date}_str`] = v[date] ? moment(v[date]).format('DD MMM YYYY, HH:mm') : '-';
          });
          return v;
        });
      }).catch(() => {
        this.loading.table = false;
      });
    },
    async downloadList() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // if (isEmpty(this.search.date_range)) {
            //   this.search.date_range = [new Date(), new Date()];
            // }
            const [start_date, end_date] = this.search.date_range;
            const response = await reportApi.downloadFlowList({
              flow_id: this.flow_id,
              workspace_id: this.activeWorkspace._id,
              start_date: moment(start_date).format('YYYY-MM-DD'),
              end_date: moment(end_date).format('YYYY-MM-DD'),
              page: this.search.page,
              type: this.search.type,
              search: this.search.search,
              date_by: this.search.date_by,
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
    async dateCheck() {
      await this.getList();
    },
  },
};
</script>
